import React from "react";
import { GatsbyBrowser, Script } from "gatsby";
import CookiesConsentBanner from "./src/components/CookiesBanner";
import { AnimatePresence } from "framer-motion";
import { TypeLocale } from "./src/types";

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ( { element, props } ) => {
    const locale = props.pageContext.locale as TypeLocale
    return (
        <>
            { process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY &&
                <Script key={ "recaptcha-script" } src={ `https://www.google.com/recaptcha/api.js?render=${ process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY }` }/>
            }
            <CookiesConsentBanner locale={locale}/>
            <AnimatePresence exitBeforeEnter>
                <div className={"md:pl--side"} key={props.location.pathname}>{element}</div>
            </AnimatePresence>
        </>
    )
}

export default wrapPageElement
