export function makeButtonClass( color: string, extra?: string ) {
    const base = `inline-flex al__it--center jf__cnt--center overflow--hidden cursor--pointer transition--colors text--bold ${ extra }`;
    return {
        fill   : `${ base } bg--${ color } hover:bg--${ color }-5 text--${ color }-1 border border--${ color } round py--10 px--lg`,
        outline: `${ base } hover:bg--${ color }-5 text--${ color } hover:text--${ color }-1 border border--${ color } round py--10 px--lg`,
        minimal: `${ base } text--${ color } hover:text--${ color }-5`,
        empty  : `text--${ color } hover:text--${ color }-5 ${ extra }`
    }
}

export function makeInputClass() {
    const base = "px--15 py--10 text--text w--100 round border border--bg"
    return {
        underline: `${ base } border-b border--border`,
        fill     : `${ base } bg--bg`
    }
}
