import React, { FC, useState } from "react";
import { motion } from "framer-motion";
import { translationMap } from "../i18n";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { makeButtonClass } from "./cssClasses";
import { Popover } from "react-tiny-popover";

type TypeCookiesPopover = {
    locale: string
    declineAllCookies: () => void
    acceptAllCookies: () => void
}

const CookiesPopover: FC<TypeCookiesPopover> = ( { locale, declineAllCookies, acceptAllCookies } ) => {
    const [ isOpen, setIsOpen ] = useState( false )
    const popOverContent =
        <motion.div className={ "bg--bg round elevation--1 p--md" } initial={ { y: 10 } } animate={ { y: 0 } }>
            <h3>Cookies</h3>
            <p className={ "my--md  max-w--40ch" }>{ translationMap( "This site uses cookies.", locale ) } <Link to={ "/cookies-policy" }>Cookies Policy</Link></p>
            <div className={ "md:ml--auto flex--center md:flex--row flex--column" }>
                <a onClick={ () => {
                    declineAllCookies()
                    setIsOpen( false )
                } }
                   className={ makeButtonClass( "accent" ).outline } href="javascript:gaOptout();">
                    { translationMap( "Deactivate Tracking", locale ) }
                </a>
                <button onClick={ () => {
                    acceptAllCookies()
                    setIsOpen( false )
                } } className={ makeButtonClass( "accent", "md:ml--lg md:mt--0 mt--md" ).fill }>{ translationMap( "Accept", locale ) }</button>
            </div>
        </motion.div>
    return (
        <Popover
            isOpen={ isOpen }
            positions={ [ 'top' ] }
            align={ "end" }
            content={ popOverContent }
            containerClassName={ "z--7" }
            padding={ 10 }
            onClickOutside={ () => setIsOpen( false ) }
        >
            <button className={ "flex--center h4 bg--bg p--sm cursor--pointer round-l fixed md:right--0 left--auto bottom--footer z--7" } onClick={ () => setIsOpen( !isOpen ) }
                    title={ `Cookie settings` }>
                🍪
            </button>
        </Popover>
    )
}

export default CookiesPopover
