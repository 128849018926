import React, { FC } from "react";
import { translationMap } from "../i18n";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { makeButtonClass } from "./cssClasses";
import CookiesPopover from "./CookiesPopover";
import { CookiesBanner } from "./cookies";

interface ICookiesBanner {
    locale: string
}

const CookiesConsentBanner: FC<ICookiesBanner> = ( { locale } ) => {
    return (
        <>
            <CookiesBanner
                dismissedChildren={ ( { acceptAllCookies, declineAllCookies } ) => <CookiesPopover locale={ locale } acceptAllCookies={ acceptAllCookies } declineAllCookies={ declineAllCookies }/> }>
                { ( { acceptAllCookies, declineAllCookies, dismissBanner } ) => (
                    <div className={ "container fixed-x--center round bottom--10 w--100 z--8 flex--center md:flex--row flex--column p--md border--md border--border bg--bg elevation--3" }
                         style={ { gap: "1rem" } }>
                        <button onClick={ dismissBanner }>
                            <svg className={ "flex--center fill--textDimmed hover:fill--text transition--colors cursor--pointer" } xmlns="http://www.w3.org/2000/svg" height="18"
                                 viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            </svg>
                        </button>
                        <p className={ "m--0" }>{ translationMap( "This site uses cookies.", locale ) } <Link to={ "/cookies-policy" }>Cookies Policy</Link></p>
                        <div className={ "md:ml--auto flex--center md:flex--row flex--column" }>
                            <a onClick={ declineAllCookies } className={ makeButtonClass( "accent" ).outline } href="javascript:gaOptout();">{ translationMap( "Deactivate Tracking", locale ) }</a>
                            <button onClick={ acceptAllCookies } className={ makeButtonClass( "accent", "md:ml--lg md:mt--0 mt--md" ).fill }>{ translationMap( "Accept", locale ) }</button>
                        </div>
                    </div>

                ) }
            </CookiesBanner>
        </>
    )
}

export default CookiesConsentBanner
