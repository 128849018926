import { useLocalization } from "gatsby-theme-i18n"

type TypeTranslations = {
    [key: string]: string
}

const translations: TypeTranslations = {
    "Contact"                                                                       : "Επικοινωνία",
    "Home"                                                                          : "Αρχική",
    "About"                                                                         : "Εμείς",
    "First Name"                                                                    : "Όνομα",
    "Last Name"                                                                     : "Επώνυμο",
    "Phone"                                                                         : "Τηλέφωνο",
    "Message"                                                                       : "Μήνυμα",
    "Send"                                                                          : "Αποστολή",
    "optional"                                                                      : "προαιρετικό",
    "Menu"                                                                          : "Μενού",
    "Services"                                                                      : "Υπηρεσίες",
    "Service"                                                                       : "Υπηρεσία",
    "View Projects"                                                                 : "Δες τα Projects",
    "All Projects"                                                                  : "Όλα τα Projects",
    "Learn More"                                                                    : "Μάθε Περισσότερα",
    "Featured Projects"                                                             : "Επιλεγμένα Projects",
    "Let's work together."                                                          : "Ας δουλέψουμε μαζί.",
    "Branding, web design and development for unique experiences!"                  : "Branding, web design και development για μοναδικές εμπειρίες!",
    "Like what you see? Contact us and lets make your brand & website look awesome!": "Σας αρέσει αυτό που βλέπετε; Επικοινωνήστε μαζί μας και ελάτε να κάνουμε το branding και το website σας μοναδικά!",
    "This site uses cookies."                                                       : "Αυτή η ιστοσελίδα χρησιμοποιεί cookies.",
    "Decline"                                                                       : "Άρνηση",
    "Accept"                                                                        : "Αποδοχή",
    "Deactivate Tracking"                                                           : "Απενεργοποιήστε την παρακολούθηση",
    "Pricing"                                                                       : "Κοστολόγιο",
    "I want this"                                                                   : "Το θέλω",
    "Starting from"                                                                 : "Ξεκινώντας από",
    "Services Details"                                                              : "Λεπτομέρειες Υπηρεσιών",
    "L.P."                                                                          : "Ε.Ε."
}

export default function useTranslation( string: string ) {
    const { locale } = useLocalization();
    return translationMap( string, locale )
}

export function translationMap( string: string, locale: string ) {
    if (locale === 'en') {
        return string
    } else {
        return translations[string] ?? string
    }
}
