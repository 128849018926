import React from "react";
import { CookiesContextProvider } from "./src/components/cookies";
import { AppStateProvider } from "./src/AppState";
import { GatsbyBrowser } from "gatsby";

const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => {
    return (
        <CookiesContextProvider>
            <AppStateProvider>
                {element}
            </AppStateProvider>
        </CookiesContextProvider>
    )
}

export default wrapRootElement
